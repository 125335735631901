import React from "react"

function ProductInformation({ informationContent }){
return (
  <div className="information content navy-background service-cta-block finish-cta-holder">
      <div className="eighty-spacer"></div>
      <div className="content-block">
          <div dangerouslySetInnerHTML={{ __html: informationContent}}/>
      </div>
      <div className="sixty-spacer"></div>
  </div>
 )
  }


export default ProductInformation
