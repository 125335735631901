import React, {useState,useEffect } from "react"
import Select from 'react-select';

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@reach/accordion"
import "@reach/accordion/styles.css"
const FullFaqs = ({ fullFaq }) => {
const faqValues = [{value: "",label: "All"},{value: "Business Savings",label: "Business Savings"},{value: "Specialist Business Finance",label: "Specialist Business Finance"}]
const [selectedOption, setSelectedOption] = useState()
const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
const [url, setUrl] = useState(pageUrl)
const [showFilter,setShowFilter] = useState(false)

const updatePosts=(e)=>{
if(url==="/business/faqs-business/"){
  setShowFilter(true)
}
}
useEffect(() => {
  updatePosts()
}, []);


const customStyles = {
  option: (provided,selectedOption) => ({
    ...provided,
  color: selectedOption ? "white" : "white",
    backgroundColor: '#041e41',
    fontSize: '22px',
    fontWeight: '200',
  }),
  control: (provided,selectedOption) => ({
    ...provided,
    backgroundColor: '#041e41',
    border: '0px',
    borderRadius: '30px',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white',
    backgroundColor: '#041e41',
    fontSize: '22px',
    fontWeight: '200',
    padding: '0 7px 0 7px',
  }),
}

  return (
    <div>
      <section className="content very-light-blue-background">
        <div className="eighty-spacer"></div>
        <div className="content-block">
          <div className="centered-title-holder">
            <h1 className="staggerFadeInFirst first-load">
              Frequently Asked Questions 
            </h1>
          </div>
          <div className="scroll-to-filter"></div>
          {showFilter ?
          <div className="filter-block">
            <div className="cta-help-field-holder faq-filter">
            <div className="filter-text staggerFadeInFirst first-load">Use the dropdown to Filter FAQs by service.
            </div>
              <div className="vacancy-select-dropdown-holder faq-filter-select staggerFadeInFirst first-load">
                  <form>
                    <Select styles = { customStyles } options={faqValues} defaultValue={faqValues[0]} onChange={e => setSelectedOption(e.value)} multi />
                    </form>
              </div>
            </div>
          </div>
          :
          null
          }
          <div className="sixty-spacer"></div>
          <div className="faq-list-container">
            {fullFaq.length
              ? fullFaq.map((cat,index) => (
                  <div key={index}>
                  {(selectedOption) ?
                    <>
                  {(cat.name === selectedOption) ?
                    <ul className="faqs-mixit-container" id="MixItUp65659F">
                      {cat.faqs.nodes.map((faq, index) => (
                        <li className="mix personal-savings " key={index}>
                          <div className="p-faq-title-holder">{faq.title}</div>
                          <Accordion collapsible>
                          {faq.childFaqs.nodes.map((item,index) => (

                              <AccordionItem className="mix personal-savings" key={index}>
                                <div className="c-faq-question-holder">
                                  <AccordionButton className="question-text">
                                    {item.title}
                                    <span className="white-mango-status-icon"></span>
                                  </AccordionButton>
                                </div>
                                <AccordionPanel className="c-faq-answer-container">
                                  <div className="c-faq-answer-holder" dangerouslySetInnerHTML={{ __html: item.content }}/>
                                </AccordionPanel>
                              </AccordionItem>

                          ))}
                          </Accordion>
                        </li>
                      ))}
                    </ul>
                    :
                    null
                  }
                  </>
                  :
                  <ul className="faqs-mixit-container" id="MixItUp65659F">
                    {cat.faqs.nodes.map((faq, index) => (
                      <li className="mix personal-savings " key={index}>
                        <div className="p-faq-title-holder">{faq.title}</div>
                        <Accordion collapsible>
                        {faq.childFaqs.nodes.map((item,index) => (

                            <AccordionItem className="mix personal-savings" key={index}>
                              <div className="c-faq-question-holder">
                                <AccordionButton className="question-text">
                                  {item.title}
                                  <span className="white-mango-status-icon"></span>
                                </AccordionButton>
                              </div>
                              <AccordionPanel className="c-faq-answer-container">
                                <div className="c-faq-answer-holder" dangerouslySetInnerHTML={{ __html: item.content }}/>
                              </AccordionPanel>
                            </AccordionItem>

                        ))}
                        </Accordion>
                      </li>
                    ))}
                  </ul>
                }

                  </div>
                ))
              : null}
          </div>
        </div>
        <div className="eighty-spacer"></div>
      </section>
    </div>
  )
}

export default FullFaqs
